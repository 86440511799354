@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.custom-slider-btn {
  -webkit-appearance: none;
  width: 100%;
  height: 60px;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 6px;
}

.custom-slider-btn::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 60px;
  height: 60px;
 
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
  border: 2px solid black;

  background-image: url("../public/arrow-btn.png");
  background-position: 0 0;
  background-size: cover;
}

.custom-slider-btn::-moz-range-thumb {
  width: 60px;
  height: 60px;

  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
  border: 2px solid black;

  background-image: url("../public/arrow-btn.png");
  background-position: 0 0;
  background-size: cover;
}

.custom-slider-btn.close::-webkit-slider-thumb {
  background-color: #dc2626;
}
.custom-slider-btn.close::-moz-range-thumb {
  background-color: #dc2626;
}

.custom-slider-btn.open::-webkit-slider-thumb {
  background-color: #16a34a;
}

.custom-slider-btn.open::-moz-range-thumb {
  background-color: #16a34a;
}

.custom-slider-btn-cover {
  position: absolute;
  width:calc(100% - 60px);
  height: 60px;
  z-index:99;
  margin-left:60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-slider-btn-cover span {
  user-select: none;
  margin-left: -60px;
}